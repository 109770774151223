/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Bottom from "./bottom"
import "./layout.css"
import SEO from "../components/seo"

const Layout = ({children}) => {
  return(
    <>
      <Header />
      <SEO />
        <main>{children}</main>
      <Bottom />
    </>
  )

  }


export default Layout
